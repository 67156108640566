.App {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  overflow: hidden;
  height: 100vh;
}
img {
  width: 100%;
}
canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 22;
}

.App-logo {
  height: 40vmin;
}
.App-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 768px) {
  .App-container {
    height: 300px;
  }
}
.App-bg {
  width: 100%;
  animation: App-logo-spin 17s linear infinite;
}
.App-title,
.App-telephone,
.App-logo,
.App-tip,
.App-qrcode,
.App-ios,
.App-android,
.App-footer > a {
  z-index: 9495;
}
.App-title {
  position: absolute;
  width: 28.13%;
  top: 9.72%;
  overflow: hidden;
}
.App-title:hover:before {
  transition: left 1s;
  left: 155%;
}
.App-title:before {
  content: "";
  position: absolute;
  width: 10%;
  height: 100%;
  left: -28%;
  overflow: hidden;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.2) 100%
  );
  transform: skewX(-33deg);
}
.App-content {
  position: relative;
}

.App-telephone {
  position: absolute;
  width: 30.1%;
  height: 60.37%;
  top: 24.63%;
  left: 18.07%;
  cursor: pointer;
}

.App-logo {
  position: absolute;
  width: 9.69%;
  height: 4.07%;
  top: 27.78%;
  left: 55.9%;
  z-index: 94;
}
/* .App-logo:hover {
  animation: bounce 1s ease infinite forwards;
} */
.App-tip {
  position: absolute;
  width: 22.97%;
  height: 6.11%;
  left: 55.89%;
  top: 33.43%;
  overflow: hidden;
}
.App-tip:hover:before {
  transition: left 1s;
  left: 150%;
}
.App-tip:before {
  content: "";
  position: absolute;
  width: 10%;
  height: 100%;
  left: -23%;
  overflow: hidden;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.2) 100%
  );
  transform: skewX(-33deg);
}
.App-ios {
  position: absolute;
  width: 11.93%;
  height: 7.04%;
  left: 55.89%;
  top: 70.83%;
  animation: rubberBand 1s linear forwards;
  animation-delay: 1.25s;
}
.App-android {
  position: absolute;
  width: 11.93%;
  height: 7.04%;
  left: 69.9%;
  top: 70.83%;
  animation: rubberBand 1s linear forwards;
  animation-delay: 1.75s;
}
.App-ios,
.App-android {
  opacity: 0;
  cursor: pointer;
}
.App-ios img,
.App-android img {
  transition: all 0.4s ease 0s;
}
.App-ios img:hover,
.App-android img:hover {
  transform: scale(1.1);
  transition: all 0.4s ease 0s;
  -webkit-transform: scale(1.1);
}
.App-qrcode {
  position: absolute;
  width: 10.42%;
  height: 18.52%;
  left: 55.89%;
  top: 45.65%;
}
@media (prefers-reduced-motion: no-preference) {
}

.App-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5.37%;
  /* background-color: #fff8d0;  */
  background-color: #EDFFF5;
  color: #666;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.App-footer a {
  margin-left: 5px;
  color: #666;
  text-decoration: none;
}
.ba{
  width: 16px;
  margin-left: 10px;
}
.app .ba{
  width: 16px;
  margin: 0 2px 0 4px;
}
.app .icp{
  display: flex;
  align-items: center;
  margin-top: 3px;
}
/* 移动端 */
.app {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: space-around;
  -webkit-overflow-scrolling: touch;
}
.app::-webkit-scrollbar {
  display: none;
}
.app img {
  width: 100%;
}
.app-tip,
.app-tel,
.app-btn {
  position: absolute;
  z-index: 9495;
  opacity: 0;
  animation: forwards;
}
.app-bg {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  animation: App-logo-spin 20s linear infinite;
}
.app-tip {
  width: 64.27%;
  top: 10%;
}
.app-tel {
  width: 68.8%;
  top: 25%;
}
.app-btn {
  width: 64%;
  top: 65.88%;
}
.app-copy {
  position: absolute;
  color: #999;
  text-align: center;
  z-index: 9495;
  font-size: 12px;
  top: 77%;
  transform: scale(0.81);
}

@keyframes App-logo-spin {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

.animate__rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

.animate__bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@keyframes bounceInDown {
  0%,
  60%,
  75%,
  90% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0) scaleY(3);
    transform: translate3d(0, -3000px, 0) scaleY(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0) scaleY(0.9);
    transform: translate3d(0, 25px, 0) scaleY(0.9);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0) scaleY(0.95);
    transform: translate3d(0, -10px, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0) scaleY(0.985);
    transform: translate3d(0, 5px, 0) scaleY(0.985);
  }

  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes backInLeft {
  0% {
    opacity: 0.7;
    -webkit-transform: translateX(-2000px) scale(0.7);
    transform: translateX(-2000px) scale(0.7);
  }

  80% {
    opacity: 0.7;
    -webkit-transform: translateX(0) scale(0.7);
    transform: translateX(0) scale(0.7);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__backInLeft {
  -webkit-animation-name: backInLeft;
  animation-name: backInLeft;
}

@keyframes bounceInRight {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0) scaleX(3);
    transform: translate3d(3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0) scaleX(1);
    transform: translate3d(-25px, 0, 0) scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0) scaleX(0.98);
    transform: translate3d(10px, 0, 0) scaleX(0.98);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0) scaleX(0.995);
    transform: translate3d(-5px, 0, 0) scaleX(0.995);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.animate__slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animate__delay-05s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.animate__delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animate__animated.animate__delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animate__animated.animate__delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
@keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg);
    transform: translate3d(-25%, 0, 0) rotate(-5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate(3deg);
    transform: translate3d(20%, 0, 0) rotate(3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg);
    transform: translate3d(-15%, 0, 0) rotate(-3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate(2deg);
    transform: translate3d(10%, 0, 0) rotate(2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg);
    transform: translate3d(-5%, 0, 0) rotate(-1deg);
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.655, 0.05, 0.755, 0.06);
    animation-timing-function: cubic-bezier(0.655, 0.05, 0.755, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.1);
    transform: translate3d(0, -15px, 0) scaleY(1.1);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.655, 0.05, 0.755, 0.06);
    animation-timing-function: cubic-bezier(0.655, 0.05, 0.755, 0.06);
    -webkit-transform: translate3d(0, -7px, 0) scaleY(1.05);
    transform: translate3d(0, -7px, 0) scaleY(1.05);
  }

  80% {
    -webkit-transform: translateZ(0) scaleY(0.95);
    transform: translateZ(0) scaleY(0.95);
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  90% {
    -webkit-transform: translate3d(0, -2px, 0) scaleY(1.02);
    transform: translate3d(0, -2px, 0) scaleY(1.02);
  }
}

.animate__bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}
.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.animate__tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

@keyframes jello {
  0%,
  11.1% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.animate__jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}
@keyframes flipInY {
  0% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
  }

  40% {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-transform: perspective(400px) rotateY(-20deg);
    transform: perspective(400px) rotateY(-20deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }

  80% {
    -webkit-transform: perspective(400px) rotateY(-5deg);
    transform: perspective(400px) rotateY(-5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    opacity: 1;
  }
}

.animate__flipInY {
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@keyframes backInDown {
  0% {
    opacity: 0.7;
    -webkit-transform: translateY(-1200px) scale(0.7);
    transform: translateY(-1200px) scale(0.7);
  }

  80% {
    opacity: 0.7;
    -webkit-transform: translateY(0) scale(0.7);
    transform: translateY(0) scale(0.7);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__backInDown {
  -webkit-animation-name: backInDown;
  animation-name: backInDown;
}
